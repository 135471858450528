import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  total: 0,
  totalSC : 0,
  subTotal : 0,
  subTotalSC: 0,
  taxPrice : 0,
  taxPriceSC :0,
  appliedAmount: 0,
  salesTax: [],
  paymentMethod: null,
  customerBalances: [],
  saleReports: [],
  salesByItemsReports: [],
  createdRepairJobID : 0,
  shippingMethod: null,
})

export const state = initState

export const getters = {
  getSubTotal: (state) => state.subTotal,
}
export const mutations ={
  setSubTotal(state, {subTotal}) {
    state.subTotal = subTotal;
  },
  setSubTotalSC(state, {subTotalSC}){
    state.subTotalSC = subTotalSC;
  },
  setPaymentMethod(state, {paymentMethod}) {
    state.paymentMethod = paymentMethod;
  },
  setSalesTax(state, {salesTax}) {
    state.salesTax = salesTax;
  },
  setTaxPrice(state, {taxPrice}){
    state.taxPrice = taxPrice;
  },
  setTaxPriceSC(state, {taxPriceSC}){
    state.taxPriceSC = taxPriceSC;
  },
  setTotal(state){
    state.total = Number((state.taxPrice
      + state.subTotal
      + (state.shippingMethod && state.shippingMethod.price > 0 ? state.shippingMethod.price : 0)
    ).toFixed(2));
  },
  setTotalSC(state){
    state.totalSC = Number((state.taxPriceSC
      + state.subTotalSC
      + (state.shippingMethod && state.shippingMethod.priceSC > 0 ? state.shippingMethod.priceSC : 0)
    ).toFixed(2));
  },
  setAppliedAmount(state, {appliedAmount}) {
    state.appliedAmount = appliedAmount;
  },
  resetStoreData(state){
    state.total = 0;
    state.totalSC = 0;
    state.subTotal = 0;
    state.subTotalSC = 0;
    state.taxPrice = 0;
    state.salesTax = [];
    state.paymentMethod = null;
    state.shippingMethod = null;
  },
  updateCreatedRepairJobID(state, newID){
    state.createdRepairJobID = newID;
  },
  setShippingMethod(state, shippingMethod){
    state.shippingMethod = shippingMethod;
  },
}

export const actions ={

  async fetchRepairsListItems({commit, dispatch}, {filters, pagingInfo, showClosedTickets}){
    let repairsListItems = []
    try{
      let filter = mapFilters(filters,pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
      filter.showClosedTickets = showClosedTickets;
      let result = await this.$axios.post(`webpos/repairs`, filter);
      if (result && !result.data.hasErrors) {
        repairsListItems = result.data;
      }
      else{
        this.$toast.error("Error while getting repair items.");
      }
    }
    catch{
      this.$toast.error("Error while getting repair items.");
    }
    return repairsListItems;
  },

  async getSalesTax({commit},customerId){
    try{
      const salesTax = await this.$axios.$get(
        '/webpos/shopping-cart/get-sales-tax/' + customerId
      )

      commit('setSalesTax', {salesTax: salesTax.value});
      return salesTax;
    }catch (e) {
      this.$toast.error("Error while getting sales tax.");
    }

  },

  async createWebPOSRepairsJobOrder({commit}, {orderData}){
    try {
      const response = await this.$axios.$post(`/webpos/repairs/create-repair-job-order`, orderData);
      if(response && !response.status){
        commit('updateCreatedRepairJobID', response.newID);
        return response;
      }
      else{
        this.$toast.error('Error while creating repair job order.');
      }
    }catch(error){
      this.$toast.error('Error while creating repair job order.');
    }
  },

  async getInitialRepairOrderData({}){
    let initialData = null;
    try{
      const result = await this.$axios.$get(
        `/webpos/repairs/initial-data`);

      if(result && !result.hasErrors){
        initialData = result.value;
      }

      else{
        this.$toast.error('Error while initializing repair order data');
      }
    }
    catch(e){
      this.$toast.error('Error while initializing repair order data');
      this.$toast.error('Error while initializing repair order data');
    }
    return initialData;
  }
}
